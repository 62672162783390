<template>
  <div>
    <section>
      <Hero>
        <div class="container">
          <HeadText :titletext="'Mein Zweitmarkt'" :subtext="'Ihr Handelsplatz für geschlossene Fondsbeteiligungen'"></HeadText>
        </div>
        <div v-if="!hasToken" class="container bg-light">
          <div class="alert alert-warning" role="alert">
            <p>Sie sind nicht angemeldet. Bitte melden Sie sich <router-link to="/login">hier</router-link> an.</p>
          </div>
        </div>
        <div v-else>
          <div class="container bg-light">
            <h3 class="text-left pt-3">Vertraulichkeitszustimmung</h3>
            <div class="row justify-content-around pt-3 pb-3">
              <div class="col-sm-12 text-left">
                <div v-if="!this.loadingSubsiteContent" v-html="this.textVertraulichkeitszustimmung"></div>
              </div>
            </div>
            <p class="text-left w-100" v-if="warning">
              <b-alert show variant="warning">
                {{ warning }}
              </b-alert>
            </p>
            <div class="row justify-content-around pt-1 pb-5">
              <div class="col-sm-12 text-center">
                <b-button id="reset-password-form-submit"
                          variant="default"
                          :pressed.sync="isWaiting"
                          @click.once="setDownloadCompliance"
                          :disabled="setComplianceDownloadTriggered">
                  <span class="pr-2" v-if="isWaiting"><b-spinner small v-if="isWaiting"></b-spinner></span><span>OK</span>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import axios from "axios";
import router from "../router";

export default {
  name: "ComplianceDownloads",
  components: {Hero,HeadText},
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
        { name: 'robots', content: "noindex, nofollow", vmid: 'robots' },
        { name: 'googlebot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'googlebot' },
        { name: 'bingbot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'bingbot' },

      ],
    }
  },
  data: () => ({
    warning: null,
    error: null,
    setComplianceDownloadTriggered: false,
    isWaiting: false,
    submitSuccess: false,
    subsiteContent: [],
    loadingSubsiteContent: false,
    textVertraulichkeitszustimmung: '',
  }),
  mounted() {
    this.loadSubsiteContent();
  },
  computed: {
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    setDownloadCompliance(event) {
      this.error = null;
      this.warning = null;
      this.setComplianceDownloadTriggered = true;
      event.preventDefault();

      let token = this.$store.getters['auth/getToken']
      if(token === '' || token === undefined){
        throw new Error('Missing Token!')
      }

      var config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const tid = urlParams.get('tid');

      this.isWaiting = true;
      axios.put( this.$store.getters.getAllEnvis.API_VERTRAULICHKEITSZUSTIMMUNG_DOWNLOADS.replace("{id}", tid),
          {
            id: this.$store.getters['user/guidFromToken'],
            subsite: this.$store.getters['getSubsite']
          },
          config)
          .then( () => {
            this.submitSuccess = true;
            this.isWaiting = false;

            router.push({name: 'fondsdetails', params: {client: this.$store.getters['getSubsite'], id: tid}});
          })
          .catch(error => {
            console.log( error );
            this.isWaiting = false;
            if ( error.response.status === 401 ) {
              this.warning = 'Sie haben hierfür keine Berechtigung.';
            }
            if ( error.response.status === 403 ) {
              this.warning = 'Vertraulichkeitszustimmung kann nicht gesetzt werden.';
            }
            else if ( error.response.status === 404 ) {
              this.warning = 'Es ist ein Fehler aufgetreten.';
            }
            else {
              this.error = error;
            }
          })
    },
    loadSubsiteContent() {
      let token = this.$store.getters['auth/getToken']
      if(token === '' || token === undefined){
        throw new Error('Missing Token!')
      }
      this.loadingSubsiteContent = true;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        crossdomain: true,
      };
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const tid = urlParams.get('tid');

      const uri = this.$store.getters.getAllEnvis.API_SUBSITE_CONTENT + '/protected/32350?placeholderReferenceId=' + tid;
      axios.get(uri, config)
          .then(response => {
            this.subsiteContent = response.data;
            this.loadingSubsiteContent = false;
            this.textVertraulichkeitszustimmung = this.getHtmlContent( response.data );
          } )
          .catch(error => {
            console.log( error );
            this.loadingSubsiteContent = true;
          })
    },
    getHtmlContent( content ) {
          return content.htmlcontent;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
}
</script>

<style scoped>

</style>